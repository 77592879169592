import { useState } from 'react';
import ButtonWrap from '../../../components/template/ButtonWrap'
import Modal from '../../../components/template/Modal'
import LoadExistingData from './LoadExistingData';
import api from '../../../components/utils/api';

const LoadExistingMergeRule = ({ setExistMergeRulePairs, title = "Load Merge Rule" }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const openModal = () => { setModalVisible(true); }
    const closeModal = () => { setModalVisible(false); }

    const onClick = (selectedMergeRule) => {
        if (selectedMergeRule) {
            api.get(`/api/post-action/merge-rules/${selectedMergeRule}`)
                .then(res => {
                    setExistMergeRulePairs(res.data.tags)
                    closeModal()
                })
                .catch(e => console.error(e))
        }
    }

    return (
        <div className='mr-2'>
            <ButtonWrap contents={title} onClick={openModal} />
            {modalVisible && <Modal
                visible={modalVisible}
                closable={true}
                maskClosable={true}
                onClose={closeModal}>{
                    <LoadExistingData
                        title={title}
                        onClick={onClick}
                        dataSource={"mergerule"}
                    />
                }</Modal>}
        </div>
    )
}

export default LoadExistingMergeRule