import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom'
import MainTitle from '../../components/template/MainTitle'
import SubTitle from '../../components/template/SubTitle'
import { classNames, getAlphabetArray, replacePeriod } from '../../components/utils/Utils'
import api from '../../components/utils/api';

const AvailableClassPage = () => {
    const params = useParams()
    const [generalModel, setGeneralModel] = useState({})
    const [classes, setClasses] = useState([])
    const [numOfClasses, setNumOfClasses] = useState(0)
    const [alphabetFilter, setAlphabetFilter] = useState('all')
    const keyMap = useMemo(() => ({ 0: 'dbVersion', 1: 'expVersion', 2: 'modelType' }), [])
    useEffect(() => {
        var newGeneralModel = {}
        if (params) {
            params.generalModelName.split("-").map((e, i) => newGeneralModel[keyMap[i]] = e)
            setGeneralModel(newGeneralModel)
        }
    }, [params, keyMap])

    useEffect(() => {
        if (Object.keys(generalModel).length > 0) {
            api.get(`/api/post-action/general-models?db_ver=${replacePeriod(generalModel.dbVersion)}&exp_ver=${generalModel.expVersion}&model_name=${replacePeriod(`${generalModel.dbVersion}_${generalModel.expVersion}_${generalModel.modelType}`)}`)
                .then(res => {
                    api.get(`/api/post-action/experiments/${res.data.general_models[0].experiment_id}/event-classes`)
                        .then(res => {
                            setNumOfClasses(res.data.event_classes.map(e => e.name).length)
                            setClasses(getAlphabetArray(res.data.event_classes.map(e => e.name)))
                        })
                        .catch(err => console.error(err))
                })
        }
    }, [generalModel])

    return (
        <div className='default-background'>
            {Object.keys(generalModel).length && <>
                <SubTitle title='Avaliable classes' />
                <MainTitle title={`${generalModel.dbVersion}_${generalModel.expVersion}_${generalModel.modelType} (${numOfClasses})`} />
                <div className='flex justify-between my-2'>{
                    ['all', ...Array.from(classes.keys())].map((e, i) => <button
                        key={i} className={classNames(
                            'py-3 text-sm text-gray-500',
                            e === alphabetFilter ? 'font-bold text-violet-500' : 'text-gray-500'
                        )}
                        onClick={() => setAlphabetFilter(e)}
                    >{e.toUpperCase()}</button>)
                }</div>
                <div>
                    {Array.from(classes.keys()).filter(e => alphabetFilter === 'all' || e === alphabetFilter).map((e, i) =>
                        <div key={i} className='text-left my-3'>
                            <div className='text-left font-bold p-3'>{`${e.toUpperCase()} (${classes.get(e).length.toString()})`}</div>
                            <hr />
                            <div className='md:grid md:grid-cols-3'>
                                {classes.get(e).map((className, idx) => <div key={idx} className='px-3 py-1'>{className}</div>)}
                            </div>
                        </div>)}
                </div>
            </>}
        </div>
    )
}

export default AvailableClassPage