import { useEffect, useState } from 'react'
import Select from 'react-select'
import api from '../../components/utils/api'

const EvaluationTestsetSelector = ({ testset, setTestset }) => {
    const [options, setOptions] = useState([])

    useEffect(() => {
        api.get('/api/benchmark-datasets/all')
            .then(res => setOptions([{ label: "Internal", value: 0 }, ...res.data.benchmark_datasets.map(e => ({ label: e.name, value: e.id }))]))
            .catch(error => console.error(error))
    }, [])

    return (
        <Select
            options={options}
            value={testset}
            onChange={(e) => { setTestset(undefined); setTestset(e) }}
            placeholder="Select a testset"
        />
    )
}

export default EvaluationTestsetSelector