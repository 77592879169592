import { classNames } from "../../components/utils/Utils";

const TrainingStatusPill = (props) => {
    const status = props.value ? props.value.toLowerCase() : "unknown";
    return (
        <span
            className={classNames(
                "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
                status.startsWith("not_ready") ? "bg-red-100 text-red-700" : null,
                status.startsWith("trainable") ? "bg-green-100 text-green-700" : null,
                status.startsWith("trained") ? "bg-blue-100 text-blue-700" : null,
            )}
        >{status.replaceAll("_", " ")}</span>
    );
}

export default TrainingStatusPill