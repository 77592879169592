import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ButtonWrap from "../../components/template/ButtonWrap"
import MainTitle from "../../components/template/MainTitle"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import Table, { SelectColumnFilter } from "../../components/table/Table"
import { useEffect, useMemo, useState } from "react"
import ButtonFull from "../../components/template/ButtonFull"
import api from "../../components/utils/api"
import DBGenerationStatusPill from "./DBGenerationStatusPill"

const DBGeneratoinPage = () => {
    const [data, setData] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        api.get('/api/db-generation/cochl-dbs')
            .then(res => setData(res.data.cochl_dbs.map(e => ({
                'db_version': e.db_ver,
                'status': e.status.name,
                'id': e.id,
                'created_at': e.created_at.split("T")[0],
            }))))
            .catch(error => console.error(error))
    }, [])

    const columns = useMemo(
        () => [
            {
                Header: "Version",
                accessor: "db_version",
            },
            {
                Header: "Created At",
                accessor: "created_at",
            },
            {
                Header: "Status",
                accessor: "status",
                Filter: SelectColumnFilter,
                filter: 'includes',
                Cell: DBGenerationStatusPill,
            },
            {
                Header: "Detail",
                accessor: "detail",
                Cell: ({ row }) => <div>
                    <ButtonFull contents={"Detail"} onClick={() => navigate(`/db-generation/detail/${row.original.id}`)} />
                </div>
            },
        ], [navigate]
    )

    return (
        <div className='default-background'>
            <div className="flex justify-between items-center my-5">
                <MainTitle title="Cochl Database" />
                <ButtonWrap contents={<FontAwesomeIcon icon={faArrowLeft} />} onClick={() => navigate('/')} />
            </div>

            <Table data={data} columns={columns} additionalButton={{
                contents: "New Database", onClick: () => {
                    if (data.filter(e => e.status !== 'GENERATED').length > 0) {
                        alert('Database generation in progress. New generation not possible until completion. Please try again later.')
                        return;
                    }
                    if (window.confirm("Are you sure you want to generate a new database? This action cannot be undone.")) {
                        api.post('/api/db-generation/cochl-dbs')
                            .then(() => navigate(0))
                            .catch(err => alert(err.response.data.message))
                    } else {
                        return;
                    }
                }
            }} />
        </div>
    )
}

export default DBGeneratoinPage;