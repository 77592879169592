import { useEffect, useState } from "react"
import ButtonWrap from "../../components/template/ButtonWrap"
import MainTitle from "../../components/template/MainTitle"
import NewInfoFormat from "../../components/template/NewInfoFormat"
import NewInfoSelector from "../../components/template/NewInfoSelector"
import NewInfoDescription from "../../components/template/NewInfoDescription"
import { useNavigate } from 'react-router-dom'
import api from "../../components/utils/api"

const NewInfoCheckbox = ({ value, setValue, elementId, label }) => (
    <div className="mx-2">
        <input id={elementId} value={value} onClick={() => setValue(!value)} type="checkbox" />
        <label className="mx-2 text-sm" htmlFor={elementId}>{label}</label>
    </div>
)

const TrainingNewModal = () => {
    const navigate = useNavigate()
    const [dbOptions, setDbOptions] = useState([])
    const [dbVersion, setDbVersion] = useState()
    const [isDisabled, setIsDisabled] = useState(false)
    const [isAdvanced, setIsAdvanced] = useState(false)
    useEffect(() => {
        api.get('/api/training/cochl_dbs')
            .then(res => setDbOptions([...res.data.cochl_dbs.map(e => ({ label: e.db_ver, value: e.id }))]))
            .catch(e => console.error(e))
    }, [])

    useEffect(() => {
        setIsDisabled(!dbVersion)
    }, [dbVersion])

    const onSubmit = () => {
        if (isAdvanced) {
            alert("The advanced experiment is not available for now")
            return;
        } else {
            api.post(`/api/training/cochl_dbs/${dbVersion.value}/experiments?advanced=${isAdvanced}`)
                .then(() => navigate(0))
                .catch(err => console.error(err))
        }
    }

    return (
        <div className='h-full'>
            <MainTitle title="New Experiment" />
            <div>
                {[
                    {
                        label: "Database Version", contents: <NewInfoSelector
                            value={dbVersion}
                            setValue={setDbVersion}
                            options={dbOptions}
                            category=""
                            noOptionsMessage="Loading..."
                        />, description: <NewInfoDescription contents={[
                            "Please choose one database with which you want to run an experiment. ",
                            "If you have permission, you can generate a new database."
                        ]} />
                    },
                    {
                        label: "Options", contents: <div className="text-left border border-[#CCCCCC] px-4 py-2 rounded flex">
                            {[
                                { value: isAdvanced, setValue: setIsAdvanced, elementId: "checkboxForAdvancedExp", label: "Advanced Experiment" }
                            ].map((e, i) => <NewInfoCheckbox key={i} value={e.value} setValue={e.setValue} elementId={e.elementId} label={e.label} />)}
                        </div>, description: <NewInfoDescription contents={[
                            "Advanced Experiment: Select it if you want to customize and run an experiment.",
                        ]} />
                    }
                ].map((e, i) => <NewInfoFormat label={e.label} contents={e.contents} description={e.description} key={i} />)}
            </div>
            <div className='my-4 text-right'>
                <ButtonWrap contents="Run" onClick={onSubmit} isDisabled={isDisabled} />
            </div>
        </div >
    )
}

export default TrainingNewModal