import { useEffect, useState } from 'react';
import MainTitle from '../../../../components/template/MainTitle'
import api from '../../../../components/utils/api';

const TimeholdModal = ({ selectedTimehold, mergeRuleId }) => {
    const [data, setData] = useState([])
    useEffect(() => {
        if (selectedTimehold && mergeRuleId) {
            api.get(`/api/post-action/merge-rules/${mergeRuleId}/timeholds/${selectedTimehold.id}`)
                .then(res => setData(res.data.values))
                .catch(err => console.error(err))
        }
    }, [selectedTimehold, mergeRuleId])

    return (
        <div>
            <MainTitle title={selectedTimehold.name} />
            <div className='grid grid-cols-5 font-bold'>
                <div></div>
                <div>nprev_0.5</div>
                <div>npass_0.5</div>
                <div>nprev_1</div>
                <div>npass_1</div>
            </div>
            {data.map((e, i) => <div key={i} className='grid grid-cols-5 gap-2 items-center my-2'>
                <div className='text-left font-bold text-sm truncate'>{e.tag.name}</div>
                {['n_prev_05', 'n_pass_05', 'n_prev_10', 'n_pass_10'].map((timehold, idx) => <div
                    key={idx}
                    className="text-left appearance-none w-full block bg-white text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight"
                >{e.value[timehold]}</div>)}
            </div>)}
        </div>
    )
}

export default TimeholdModal