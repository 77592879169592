const NewInfoFloatInput = ({ value, setValue }) => (
    <input
        className="text-center appearance-none w-full block bg-white text-gray-700 border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none"
        type='number'
        step='0.01'
        max='0.99'
        min='0'
        defaultValue={value}
        onChange={(evt) => setValue(evt.target.value)}
    />
)

export default NewInfoFloatInput