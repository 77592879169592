import { useEffect, useState } from "react";
import VerticalStepper from "./VerticalStepper";

const getStepStatus = (traceCode, stepId) => {
    const stepNumber = Math.floor(traceCode / 10) % 10;
    return stepId < stepNumber ? 'done' : stepId === stepNumber ? 'inprogress' : 'waiting';
}

const getSubStepStatus = (traceCode, stepId, subStepId) => {
    const subStepNumber = traceCode % 10;
    return stepId < Math.floor(traceCode / 10) % 10 ? 'done' :
        stepId === Math.floor(traceCode / 10) % 10 && subStepId < subStepNumber ? 'done' :
            stepId === Math.floor(traceCode / 10) % 10 && subStepId === subStepNumber ? 'inprogress' : 'waiting';
}

const updateSteps = (traceCode, steps) => {
    return steps.map(step => ({
        ...step,
        stepStatus: traceCode % 100 === 0 ? 'done' : getStepStatus(traceCode, step.id),
        subSteps: step.subSteps.map(subStep => ({
            ...subStep,
            subStepStatus: traceCode % 100 === 0 ? 'done' : getSubStepStatus(traceCode, step.id, subStep.id)
        }))
    }));
}

const TraceStepper = ({ defaultSteps, traceCode }) => {
    const [steps, setSteps] = useState([])
    useEffect(() => {
        const newSteps = updateSteps(traceCode + 1, defaultSteps)
        setSteps([...newSteps])
    }, [traceCode, defaultSteps])
    return steps.length !== 0 && (
        <div className="p-4"><VerticalStepper steps={steps} /></div>
    )
}

export default TraceStepper