import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ModelSelectionPage from '../../pages/ModelSelectionPage/ModelSelectionPage';
import SignIn from './SignIn';
import PrivateRoute from './PrivateRoute';
import PostActionPage from '../../pages/PostActionPage/PostActionPage';
import AvailableClassPage from '../../pages/AvailableClassPage/AvailableClassPage';
import MergeRuleDetailPage from '../../pages/PostActionPage/subroutes/detail/MergeRuleDetailPage';
import ConvertPage from '../../pages/PostActionPage/subroutes/convert/ConvertPage';
import ThresholdPage from '../../pages/PostActionPage/subroutes/threshold/ThresholdPage';
import TimeholdPage from '../../pages/PostActionPage/subroutes/timehold/TimeholdPage';
import ServicePage from '../../pages/PostActionPage/subroutes/service/ServicePage';
import EvaluationPage from '../../pages/PostActionPage/subroutes/evaluation/EvaluationPage';
import LoadExistingMergeRuleDetail from '../../pages/PostActionPage/components/LoadExistingMergeRuleDetail';
import MainPage from '../../pages/MainPage/MainPage';
import TrainingPage from '../../pages/TrainingPage/TrainingPage';
import DBGeneratoinPage from '../../pages/DBGenerationPage/DBGeneratoinPage';
import DBGenarationDetail from '../../pages/DBGenerationPage/DBGenarationDetail';
import { useEffect, useState } from 'react';
import TrainingDetail from '../../pages/TrainingPage/TrainingDetail';
import TrainingReportPage from '../../pages/TrainingReportPage/TrainingReportPage';
import EvaluationComparisonPage from '../../pages/EvaluationComparisonPage/EvaluationComparisonPage';

const DefaultRouter = () => {
    const [authenticated, setAuthenticated] = useState(localStorage.getItem('expiredAt') !== null);

    useEffect(() => {
        const expiredAt = localStorage.getItem('expiredAt')
        // verify if there is exp in localstorage
        if (expiredAt) {
            setAuthenticated(false)
            // verify if the expiration date doesn't exceed
            const expirationDate = new Date(expiredAt);
            const currentDate = new Date();
            if (currentDate < expirationDate) {
                setAuthenticated(true)
            }
        }
    }, [])
    return (
        <Router>
            <div className='bg-gray-100 h-full w-full min-h-screen items-center'>
                <Routes>
                    {/* TODO: need to change the main page later */}
                    <Route path='/' element={
                        <PrivateRoute
                            authenticated={authenticated}
                            component={<MainPage />}
                        />} />
                    <Route path='/signin' element={<SignIn setAuthenticated={setAuthenticated} />} />
                    {/* for db gneration */}
                    <Route path='/db-generation' element={
                        <PrivateRoute
                            authenticated={authenticated}
                            component={<DBGeneratoinPage />}
                        />} />
                    <Route path='/db-generation/detail/:id' element={
                        <PrivateRoute
                            authenticated={authenticated}
                            component={<DBGenarationDetail />}
                        />} />
                    {/* for training part */}
                    <Route path='/training' element={
                        <PrivateRoute
                            authenticated={authenticated}
                            component={<TrainingPage />}
                        />} />
                    <Route path='/training/detail/:id' element={
                        <PrivateRoute
                            authenticated={authenticated}
                            component={<TrainingDetail />}
                        />} />
                    {/* for post action part */}
                    <Route path='/model-selection' element={
                        <PrivateRoute
                            authenticated={authenticated}
                            component={<ModelSelectionPage />}
                        />} />
                    <Route path='/post-action' element={
                        <PrivateRoute
                            authenticated={authenticated}
                            component={<PostActionPage />}
                        />}>
                        <Route path='/post-action/detail' element={
                            <PrivateRoute
                                authenticated={authenticated}
                                component={<MergeRuleDetailPage />}
                            />} />
                        <Route path='/post-action/convert' element={
                            <PrivateRoute
                                authenticated={authenticated}
                                component={<ConvertPage />}
                            />} />
                        <Route path='/post-action/threshold' element={
                            <PrivateRoute
                                authenticated={authenticated}
                                component={<ThresholdPage />}
                            />} />
                        <Route path='/post-action/evaluation' element={
                            <PrivateRoute
                                authenticated={authenticated}
                                component={<EvaluationPage />}
                            />} />
                        <Route path='/post-action/timehold' element={
                            <PrivateRoute
                                authenticated={authenticated}
                                component={<TimeholdPage />}
                            />} />
                        <Route path='/post-action/service' element={
                            <PrivateRoute
                                authenticated={authenticated}
                                component={<ServicePage />}
                            />} />
                    </Route>
                    <Route path='/all-classes/:generalModelName' element={
                        <PrivateRoute
                            authenticated={authenticated}
                            component={<AvailableClassPage />}
                        />} />
                    <Route path='/training-report/:id' element={
                        <PrivateRoute
                            authenticated={authenticated}
                            component={<TrainingReportPage />}
                        />} />
                    <Route path='/merge-rule/:id' element={
                        <PrivateRoute
                            authenticated={authenticated}
                            component={<LoadExistingMergeRuleDetail />}
                        />} />
                    {/* for evaluation comparison */}
                    <Route path='/evaluation-comparison' element={
                        <PrivateRoute
                            authenticated={authenticated}
                            component={<EvaluationComparisonPage />}
                        />} />
                </Routes>
            </div>
        </Router>
    )
}

export default DefaultRouter;