export function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export const getAlphabetArray = (arr) => {
    const map = new Map()
    arr.forEach(element =>
        !map.has(element[0].toLowerCase())
            ? map.set(element[0].toLowerCase(), [element])
            : map.get(element[0].toLowerCase()).push(element)
    );
    return map;
}

export const replacePeriod = (str) => (str.replaceAll(".", "%2E"))

export const zip = (a, b) => a.map((k, i) => [k, b[i]]);