const NewInfoFormat = ({ label, contents, description = <></> }) => (
    <div className='my-3'>
        <label className="block text-gray-700 text-sm font-bold mb-2 text-left">
            {label}
        </label>
        {description}
        {contents}
    </div>
)

export default NewInfoFormat