import { useEffect, useState } from 'react';
import MainTitle from '../../../../components/template/MainTitle'
import ButtonWrap from '../../../../components/template/ButtonWrap'
import { useNavigate } from 'react-router-dom'
import NewInfoFormat from '../../../../components/template/NewInfoFormat'
import NewInfoInput from '../../../../components/template/NewInfoInput'
import NewInfoSelector from '../../../../components/template/NewInfoSelector'
import NewInfoDescription from '../../../../components/template/NewInfoDescription'
import api from '../../../../components/utils/api';

const ServiceNewModal = ({ state, closeModal }) => {
    const [serviceName, setServiceName] = useState("")
    const [serviceGroup, setServiceGroup] = useState("")
    const [modelType, setModelType] = useState()
    const [convertedModel, setConvertedModel] = useState()
    const [threshold, setThreshold] = useState()
    const [timehold, setTimehold] = useState()
    const [modelTypeOption, setModelTypeOption] = useState([])
    const [convertedModelOption, setConvertedModelOption] = useState({})
    const [thresholdOption, setThresholdOption] = useState([])
    const [timeholdOptoin, setTimeholdOption] = useState([])
    const [isDisabled, setIsDisabled] = useState(true)

    const navigate = useNavigate()

    useEffect(() => {
        if (state) {
            // get threshold list to set it as an option for threshold
            api.get(`/api/post-action/merge-rules/${state.selectedMergeRule.id}/thresholds?general_model_id=${state.generalModels.id}`)
                .then(res => setThresholdOption(res.data.thresholds))
                .catch(err => console.error(err))
            // get timehold list to set it as make an option for timehold
            api.get(`/api/post-action/merge-rules/${state.selectedMergeRule.id}/timeholds?general_model_id=${state.generalModels.id}`)
                .then(res => setTimeholdOption(res.data.timeholds))
                .catch(err => console.error(err))
            // get converted model list to set it as make an option for converted model
            api.get(`/api/post-action/merge-rules/${state.selectedMergeRule.id}/converted-models?general_model_id=${state.generalModels.id}`)
                .then((res) => setConvertedModelOption(res.data.converted_models))
                .catch(err => console.error(err))
        }
    }, [state])

    useEffect(() => {
        if (convertedModel) {
            setModelTypeOption(convertedModel.label.includes('int8') ? [{ label: 'model_main', value: ['model_main'] },] :
                [
                    { label: 'model_all', value: ['model_all'] },
                    { label: 'model_main', value: ['model_main'] },
                    { label: 'model_pre & model_main', value: ['model_pre', 'model_main'] },
                ])
        }
    }, [convertedModel])

    useEffect(() => {
        setIsDisabled(!(serviceName && serviceGroup && modelType && convertedModel && threshold && timehold))
    }, [serviceName, serviceGroup, modelType, convertedModel, threshold, timehold])

    const onClick = () => {
        setIsDisabled(true)
        api.post(`/api/post-action/merge-rules/${state.selectedMergeRule.id}/services`, {
            group_name: serviceGroup,
            name: serviceName,
            model_type: modelType.value,
            converted_model_id: convertedModel.value,
            threshold_id: threshold.value,
            timehold_id: timehold.value,
            general_model_id: state.generalModels.id
        })
            .then(() => navigate(0))
            .catch(err => console.error(err))
        closeModal()
    }

    return (
        <div className='h-full'>
            <MainTitle title="Export a New Service" />
            <div className='md:grid md:grid-cols-2 md:gap-3'>
                {[
                    {
                        label: "Service Group", contents: <NewInfoInput value={serviceGroup} setValue={setServiceGroup} />,
                        description: <NewInfoDescription contents={[
                            "Set a Service Group. Usually, it represents the client's company name. ('Hanhwa_techwin', 'Ghost_robotics', etc.)",
                            "It will determine the path in 'eats-service' bucket. (gs://eats-service/SERVICE_GROUP/service_name)"
                        ]} />
                    },
                    {
                        label: "Service Name", contents: <NewInfoInput value={serviceName} setValue={setServiceName} />,
                        description: <NewInfoDescription contents={[
                            "Set a Service Name. Usually, it literally represents the service name. ('emergency-detection', 'gunshot-detection', etc.)",
                            "It will determine the path in 'eats-service' bucket. (gs://eats-service/service_group/SERVICE_NAME)"
                        ]} />
                    },
                    {
                        label: "Converted Model", contents: <NewInfoSelector
                            value={convertedModel}
                            setValue={setConvertedModel}
                            options={Object.entries(convertedModelOption).filter(([_, v]) => Object.keys(v).length !== 0).filter(([_, v]) => v.status === 5).map(([k, v]) => ({ label: k, value: v.id }))}
                            category="converted model"
                        />, description: <div></div>
                    },
                    {
                        label: "Model Type", contents: <NewInfoSelector
                            value={modelType}
                            setValue={setModelType}
                            options={modelTypeOption}
                            category="model type"
                            noOptionsMessage="Please select a converted model first"
                        />, description: <NewInfoDescription contents={[
                            "Model_pre: a model for preproccssing",
                            "Model_main: a model for inferencing",
                            "Model_all: a model combined with the model_pre and model_main"
                        ]} />
                    },
                    {
                        label: "Threshold", contents: <NewInfoSelector
                            value={threshold}
                            setValue={setThreshold}
                            options={thresholdOption.map(e => ({ label: e.name, value: e.id }))}
                            category="threshold"
                        />, description: <div></div>
                    },
                    {
                        label: "Timehold", contents: <NewInfoSelector
                            value={timehold}
                            setValue={setTimehold}
                            options={timeholdOptoin.map(e => ({ label: e.name, value: e.id }))}
                            category="timehold"
                        />, description: <div></div>
                    },
                ].map((e, i) => <NewInfoFormat label={e.label} contents={e.contents} description={e.description} key={i} />)}
            </div>
            <div className='my-4 text-right'>
                <ButtonWrap contents="Export" onClick={onClick} isDisabled={isDisabled} />
            </div>
        </div >
    )
}

export default ServiceNewModal