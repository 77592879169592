import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const clientId = process.env.REACT_APP_CLIENT_ID;

const GoogleSignInOut = ({ setAuthenticated }) => {
    const loginRef = useRef()
    const navigate = useNavigate()

    useEffect(() => {
        // clear localstorage
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('expiredAt')

        window.google.accounts.id.initialize({
            client_id: clientId,
            callback: (res) => {
                if (res) {
                    axios.post('/api/auth/token', { token: res.credential })
                        .then(res => {
                            localStorage.setItem('accessToken', res.data.access_token);
                            localStorage.setItem('refreshToken', res.data.refresh_token);
                            localStorage.setItem('expiredAt', res.data.exp);
                            setAuthenticated(true);
                            navigate('/');
                        })
                        .catch(error => console.error(error))
                }
            },
            auto_select: false,
            use_fedcm_for_prompt: true,
        })
        window.google.accounts.id.renderButton(
            loginRef.current, {
            type: "standard",
            theme: "outline",
        },
        )
    }, [setAuthenticated, navigate])

    return (
        <div className='w-{200px} m-auto'>
            <div ref={loginRef} />
        </div>
    )
};

export default GoogleSignInOut;