import './App.css';
import DefaultRouter from './components/route/DefaultRouter';

function App() {
  return (
    <div className="App">
      <DefaultRouter />
    </div>
  );
}

export default App;
