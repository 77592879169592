import { useEffect, useState } from 'react';
import MainTitle from '../../../components/template/MainTitle'
import NewInfoFormat from '../../../components/template/NewInfoFormat'
import NewInfoSelector from '../../../components/template/NewInfoSelector'
import ButtonWrap from '../../../components/template/ButtonWrap'
import { classNames } from '../../../components/utils/Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'
import { Link } from 'react-router-dom'
import api from '../../../components/utils/api';
import axios from 'axios';
import SubTitle from '../../../components/template/SubTitle';

const LoadExistingData = ({ title, onClick, dataSource }) => {
    const [dbOptions, setDbOptions] = useState([])
    const [expOptions, setExpOptions] = useState([])
    const [modelTypeOptions, setModelTypeOptions] = useState([])
    const [dataOptions, setDataOptions] = useState([])
    const [mergeRules, setMergeRules] = useState([])
    const [selectedMergeRule, setSelectedMergeRule] = useState()
    const [dbVersion, setDbVersion] = useState()
    const [expVersion, setExpVersion] = useState()
    const [modelType, setModelType] = useState()
    const [data, setData] = useState([])

    useEffect(() => {
        // initialize the values
        setMergeRules([])
        setSelectedMergeRule()
    }, [dbVersion, expVersion])

    useEffect(() => {
        axios.get('/api/model-selection/dbs')
            .then(res => setDbOptions(res.data.dbs))
            .catch(e => console.error(e))
    }, [])

    useEffect(() => {
        // initialize the values
        setExpVersion()
        setExpOptions([])
        if (dbVersion) {
            axios.get(`/api/model-selection/experiments?db_ver=${dbVersion.value}`)
                .then(res => setExpOptions(res.data.experiments))
                .catch(e => console.error(e))
        }
    }, [dbVersion])

    const onClickSearch = () => {
        if (dbVersion && expVersion) {
            setModelType()
            setData()
            api.get(`/api/post-action/experiments?db_ver=${dbVersion.value}&exp_ver=${expVersion.value}`)
                .then(res => {
                    api.get(`/api/post-action/experiments/${res.data.experiments[0].id}`)
                        .then(experiment => {
                            setMergeRules(experiment.data.merge_rules)
                            setModelTypeOptions(experiment.data.general_models.map(e => ({ label: e.name.split("_").slice(2).join('_'), value: e.id })))
                        })
                })
                .catch(err => console.error(err))
        }
    }

    useEffect(() => {
        if (modelType) {
            setData()
            api.get(`/api/post-action/merge-rules/${selectedMergeRule}/${dataSource}?general_model_id=${modelType.value}`)
                .then(res => setDataOptions(res.data[dataSource].map(e => ({ label: e.name, value: e.id }))))
                .catch(err => console.error(err))
        }
    }, [modelType, selectedMergeRule, dataSource])

    return (
        <div>
            <MainTitle title={title} />
            <div className='grid grid-cols-2 gap-1'>
                {[
                    {
                        label: "Database Version", contents: <NewInfoSelector
                            value={dbVersion}
                            setValue={setDbVersion}
                            options={dbOptions.map(e => ({ label: e, value: e }))}
                            category=""
                            noOptionsMessage="Loading..."
                        />, description: <></>
                    },
                    {
                        label: "Experiment Number", contents: <NewInfoSelector
                            value={expVersion}
                            setValue={setExpVersion}
                            options={expOptions.map(e => ({ label: e, value: e }))}
                            category=""
                            noOptionsMessage="Please select the database version first"
                        />, description: <></>
                    },
                ].map((e, i) => <NewInfoFormat label={e.label} contents={e.contents} description={e.description} key={i} />)}
            </div>
            <div className='text-right'>
                <ButtonWrap contents="Search" onClick={onClickSearch} isDisabled={!(dbVersion && expVersion)} />
            </div>
            {mergeRules.length !== 0 && <div>
                <SubTitle title={"Merge rule"} />
                <ul className='my-2 border rounded p-4 md:grid md:grid-cols-2 text-left'>
                    {mergeRules.map((e, i) => <li
                        key={i}
                        className={classNames("flex px-2 py-1 cursor-pointer", e.id === selectedMergeRule ? "bg-violet-100 text-violet-500 border-violet-500" : "")}
                    >
                        <p onClick={() => setSelectedMergeRule(e.id)}>{e.name}</p>
                        <Link
                            target='_blank' rel="noopener noreferrer"
                            to={`/merge-rule/${e.id}`}
                            className="mx-1"
                        ><FontAwesomeIcon icon={faCircleQuestion} size='sm' color='gray' /></Link>
                    </li>)}
                </ul>
                {
                    dataSource === "mergerule" ?
                        <div className='text-right'>
                            <ButtonWrap contents={"Load"} onClick={() => onClick(selectedMergeRule)} isDisabled={!selectedMergeRule} />
                        </div> :
                        <div>
                            {selectedMergeRule &&
                                <div>
                                    <div className='grid grid-cols-2 gap-1'>
                                        {[
                                            {
                                                label: "Model Type", contents: <NewInfoSelector
                                                    value={modelType}
                                                    setValue={setModelType}
                                                    options={modelTypeOptions}
                                                    category=""
                                                    noOptionsMessage="Loading..."
                                                />, description: <></>
                                            },
                                            {
                                                label: dataSource.charAt(0).toUpperCase() + dataSource.slice(1).toLowerCase(), contents: <NewInfoSelector
                                                    value={data}
                                                    setValue={setData}
                                                    options={dataOptions}
                                                    category=""
                                                    noOptionsMessage="Loading..."
                                                />, description: <></>
                                            },
                                        ].map((e, i) => <NewInfoFormat label={e.label} contents={e.contents} description={e.description} key={i} />)}
                                    </div>
                                    <div className='text-right'>
                                        <ButtonWrap contents={"Load"} onClick={() => onClick(selectedMergeRule, data.value)} isDisabled={!(selectedMergeRule && data)} />
                                    </div>
                                </div>}
                        </div>
                }
            </div>}
        </div >
    )
}

export default LoadExistingData