import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ButtonWrap from "../../components/template/ButtonWrap"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useEffect, useMemo, useState } from "react"
import Table, { SelectColumnFilter } from "../../components/table/Table"
import Modal from "../../components/template/Modal"
import TrainingNewModal from "./TrainingNewModal"
import MainTitle from "../../components/template/MainTitle"
import ButtonFull from "../../components/template/ButtonFull"
import api from "../../components/utils/api"
import TrainingStatusPill from "./TrainingStatusPill"


const TrainingPage = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [data, setData] = useState([])
    const openModal = () => { setModalVisible(true); }
    const closeModal = () => { setModalVisible(false); }

    const navigate = useNavigate()

    useEffect(() => {
        api.get(`/api/training/experiments`)
            .then(res => setData(res.data.experiments))
            .catch(err => console.error(err))
    }, [])

    const columns = useMemo(
        () => [
            {
                Header: "Created At",
                accessor: "created_at",
            },
            {
                Header: "Database",
                accessor: "cochl_db.name",
                Filter: SelectColumnFilter,
                filter: 'exactText',
            },
            {
                Header: "Experiment",
                accessor: "exp_ver",
            },
            {
                Header: "Status",
                accessor: "status.name",
                Filter: SelectColumnFilter,
                filter: 'includes',
                Cell: TrainingStatusPill,
            },
            {
                Header: "Detail",
                accessor: "detail",
                Cell: ({ row }) => <div>
                    <ButtonFull contents={"Detail"} onClick={() => navigate(`/training/detail/${row.original.id}`)} />
                </div>
            },
        ], [navigate]
    )

    return (
        <div className='default-background'>
            <div className="flex justify-between items-center my-5">
                <MainTitle title="Experiments" />
                <ButtonWrap contents={<FontAwesomeIcon icon={faArrowLeft} />} onClick={() => navigate('/')} />
            </div>

            <Table data={data} columns={columns} additionalButton={{ contents: "New Experiment", onClick: () => openModal() }} />
            {modalVisible && <Modal
                visible={modalVisible}
                closable={true}
                maskClosable={true}
                onClose={closeModal}>{<TrainingNewModal />}</Modal>}
        </div>
    )
}

export default TrainingPage