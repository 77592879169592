import { useEffect, useState } from 'react';
import MainTitle from '../../../../components/template/MainTitle'
import ButtonWrap from '../../../../components/template/ButtonWrap'
import NewInfoFormat from '../../../../components/template/NewInfoFormat'
import NewInfoSelector from '../../../../components/template/NewInfoSelector'
import NewInfoFloatInput from '../../../../components/template/NewInfoFloatInput'
import NewInfoIntInput from '../../../../components/template/NewInfoIntInput'
import NewInfoDescription from '../../../../components/template/NewInfoDescription'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../../../components/utils/api';
import { faAnglesDown, faAnglesRight } from '@fortawesome/free-solid-svg-icons';

const EvaluationNewModal = ({ state, closeModal }) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [threshold, setThreshold] = useState()
    const [useNumpy, setUseNumpy] = useState({ label: "Yes", value: true })
    const [testset, setTestset] = useState({ label: "Internal", value: 0 })
    const [testsetOptions, setTestsetOptions] = useState([])
    const [minimumTestsetQuantity, setMinimumTestsetQuantity] = useState(50)
    const [minimumThreshold, setMinimumThreshold] = useState(0.15)
    const [maximumThreshold, setMaximumThreshold] = useState(0.75)
    const [thresholdOption, setThresholdOption] = useState([])
    const [isAdvanced, setIsAdvanced] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        api.get('/api/benchmark-datasets/all')
            .then(res => setTestsetOptions([{ label: "Internal", value: 0 }, ...res.data.benchmark_datasets.map(e => ({ label: e.name, value: e.id }))]))
            .catch(error => console.error(error))
    }, [])

    useEffect(() => {
        setIsDisabled(!(threshold && useNumpy && testset && minimumTestsetQuantity && minimumThreshold && maximumThreshold))
    }, [threshold, useNumpy, testset, minimumTestsetQuantity, minimumThreshold, maximumThreshold])

    useEffect(() => {
        if (state) {
            // get threshold list to set it as an option for threshold
            api.get(`/api/post-action/merge-rules/${state.selectedMergeRule.id}/thresholds?general_model_id=${state.generalModels.id}`)
                .then(res => setThresholdOption(res.data.thresholds))
                .catch(err => console.error(err))
        }
    }, [state])

    const onClick = () => {
        setIsDisabled(true)
        api.post(`/api/post-action/thresholds/${threshold.value}/evaluations`, {
            use_numpy: useNumpy.value,
            testset: testset.value,
            minimum_testset_quantity: minimumTestsetQuantity,
            minimum_threshold: minimumThreshold,
            maximum_threshold: maximumThreshold,
        })
            .then(() => navigate(0))
            .catch(err => console.error(err))
        closeModal()
    }

    return (
        <div className='h-full'>
            <MainTitle title="Evaluate" />
            <div className='md:grid md:grid-cols-2 md:gap-3'>
                {[
                    {
                        label: "Threshold", contents: <NewInfoSelector
                            value={threshold}
                            setValue={setThreshold}
                            options={thresholdOption.map(e => ({ label: e.name, value: e.id }))}
                            category="threshold"
                        />, description: <NewInfoDescription contents={[
                            'Select a threshold that this evaluation will base on.',
                        ]} />
                    },
                    {
                        label: "Target Testset", contents: <NewInfoSelector
                            value={testset}
                            setValue={setTestset}
                            options={testsetOptions}
                            category=""
                        />, description: <NewInfoDescription contents={[
                            'Target testset name. For now, only Internal is available.',
                        ]} />
                    },
                ].map((e, i) => <NewInfoFormat label={e.label} contents={e.contents} description={e.description} key={i} />)}
            </div>
            <div className='text-left text-lg text-gray-500'>
                <button type='button' onClick={() => setIsAdvanced(!isAdvanced)}>Advanced Option <FontAwesomeIcon icon={isAdvanced ? faAnglesDown : faAnglesRight} /></button>
            </div>
            {isAdvanced && <div className='md:grid md:grid-cols-2 md:gap-3'>
                {[
                    {
                        label: "Use Prepared Vector (without GPU)", contents: <NewInfoSelector
                            value={useNumpy}
                            setValue={setUseNumpy}
                            options={[useNumpy]}
                            category=""
                        />, description: <NewInfoDescription contents={[
                            'Select between using a prepared vector file without GPU or creating by yourself with GPU.',
                            'For now, Yes is only available.'
                        ]} />
                    },
                    {
                        label: "Minimun Quantity of Testset", contents: <NewInfoIntInput value={minimumTestsetQuantity} setValue={setMinimumTestsetQuantity} />,
                        description: <NewInfoDescription contents={[
                            "Set the minimum number of samples in a class to get an optimized threshold. If one class has fewer samples than this value, the class cannot have an optimized threshold."
                        ]} />
                    },
                    {
                        label: "Minimun Threshold", contents: <NewInfoFloatInput value={minimumThreshold} setValue={setMinimumThreshold} />,
                        description: <NewInfoDescription contents={[
                            "Set a lower limit for the threshold. The optimized threshold cannot be less than this value. "
                        ]} />
                    },
                    {
                        label: "Maximum Threshold", contents: <NewInfoFloatInput value={maximumThreshold} setValue={setMaximumThreshold} />,
                        description: <NewInfoDescription contents={[
                            "Set a upper limit for the threshold. The optimized threshold cannot exceed this value. "
                        ]} />
                    },
                ].map((e, i) => <NewInfoFormat label={e.label} contents={e.contents} description={e.description} key={i} />)}
            </div>}
            <div className='my-4 text-right'>
                <ButtonWrap contents="Evaluate" onClick={onClick} isDisabled={isDisabled} />
            </div>
        </div>
    )
}

export default EvaluationNewModal