import { useEffect, useState } from 'react';
import MainTitle from '../../../../components/template/MainTitle'
import api from '../../../../components/utils/api';

const ThresholdModal = ({ selectedThreshold, mergeRuleId }) => {
    const [data, setData] = useState([])
    useEffect(() => {
        if (selectedThreshold && mergeRuleId) {
            api.get(`/api/post-action/merge-rules/${mergeRuleId}/thresholds/${selectedThreshold.id}`)
                .then(res => setData(res.data.values))
                .catch(err => console.error(err))
        }
    }, [selectedThreshold, mergeRuleId])
    return (
        <div>
            <MainTitle title={selectedThreshold.name} />
            <div className='md:grid md:grid-cols-2 gap-3 my-3'>
                {data.map((e, i) => <div key={i} className='grid grid-cols-2 items-center'>
                    <div className='text-left font-bold text-sm'>{e.tag.name}</div>
                    <div className="appearance-none w-full block bg-white text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight">{e.value}</div>
                </div>)}
            </div>
        </div>
    )
}

export default ThresholdModal