import { useNavigate } from 'react-router-dom';
import NewMergeRuleModal from './NewMergeRuleModal';
import ListSelector from '../../components/template/ListSelector';

const MergeRuleSelector = ({
    mergeRules,
    selectedMergeRule,
    generalModel
}) => {
    const navigate = useNavigate()
    return (
        <ListSelector
            obejcts={mergeRules}
            selectedObject={selectedMergeRule}
            onClick={(obj) => navigate('/post-action/detail', { state: { ...generalModel, selectedMergeRule: obj } })}
            modalContents={<NewMergeRuleModal generalModel={generalModel} mergeRules={mergeRules} />}
            addNewString={' Add a Merge Rule'}
        />
    )
}

export default MergeRuleSelector