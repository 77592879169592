import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import MergeRulePairTemplate from '../../components/MergeRulePairTemplate';
import api from '../../../../components/utils/api';

const MergeRuleDetailPage = () => {
    const { state } = useLocation();
    const [mergeRulePairs, setMergeRulePairs] = useState([])

    useEffect(() => {
        api.get(`/api/post-action/merge-rules/${state.selectedMergeRule.id}`)
            .then(res => {
                setMergeRulePairs(res.data.tags)
            })
            .catch(e => console.error(e))
    }, [state])

    return (
        <div>
            {mergeRulePairs.map((rep, i) => <MergeRulePairTemplate
                key={i}
                representativeClass={rep.name}
                pairedClasses={rep.merged_classes}
            />)}
        </div>
    )
}

export default MergeRuleDetailPage