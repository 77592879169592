import SubTitle from '../../../components/template/SubTitle'
import Select from 'react-select'

const MergeRulePairTemplate = ({ representativeClass, pairedClasses }) => (
    <div className='grid grid-cols-4 my-2'>
        <SubTitle title={representativeClass} />
        <div className='col-span-3'>
            <Select
                options={pairedClasses.map(e => ({ label: e, value: e }))}
                value={pairedClasses.map(e => ({ label: e, value: e }))}
                isMulti
                isDisabled
            />
        </div>
    </div>
)


export default MergeRulePairTemplate