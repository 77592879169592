import SubTitle from "../../components/template/SubTitle";
import { classNames } from "../../components/utils/Utils";
import { COLUMN_NAME, getComparison } from "./utils";

const EvaluationComparisonSummaryColumn = ({ evaluation1, evaluation2 }) => (
    <div>
        {evaluation1 && Object.keys(evaluation1).length !== 0 &&
            <div className="p-2">
                <div className="text-lg">
                    <SubTitle title="Summary" />
                </div>
                {Object.entries(evaluation1.threshold_evaluation_summary).map(([k, v], i) => (
                    <div key={i} className={classNames("flex justify-between py-2", i === 0 ? 'border-y' : 'border-b')}>
                        <p className="font-bold">{COLUMN_NAME[k]}</p>
                        <p className={classNames(
                            evaluation2 && evaluation2.hasOwnProperty('threshold_evaluation_summary') && getComparison(v, evaluation2.threshold_evaluation_summary[k])
                        )}>{v}</p>
                    </div>
                ))}
            </div>
        }
    </div>
);

const EvaluationComparisonSummary = ({ evaluation1, evaluation2 }) => {
    return (
        <div className="grid grid-cols-2 gap-2 text-sm">
            <EvaluationComparisonSummaryColumn
                evaluation1={evaluation1}
                evaluation2={evaluation2}
            />
            <EvaluationComparisonSummaryColumn
                evaluation1={evaluation2}
                evaluation2={evaluation1}
            />
        </div>
    );
}


export default EvaluationComparisonSummary