import GoogleSignInOut from './GoogleSignInOut'

const SignIn = ({ setAuthenticated }) => {
    return (
        <div className='bg-white w-full max-w-[800px] h-full min-h-screen shadow m-auto'>
            <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 md:w-96 max-w-sm">
                <div className="w-32 m-auto"><img src='eats_logo.png' alt='logo' /></div>
                <p className="text-xl my-5 text-center">EATS is Audio Training System</p>
                <div className="w-48 m-auto"><GoogleSignInOut setAuthenticated={setAuthenticated} /></div>
            </div>
        </div>
    )
}

export default SignIn;
