import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ButtonWrap from "../../components/template/ButtonWrap"
import MainTitle from "../../components/template/MainTitle"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import EvaluationTestsetSelector from "./EvaluationTestsetSelector"
import NewInfoFormat from "../../components/template/NewInfoFormat"
import EvaluaitonComparisonColumns from "./EvaluaitonComparisonColumns"

const EvaluationComparisonPage = () => {
    const [testset, setTestset] = useState()
    const navigate = useNavigate()
    return (
        <div className='default-background'>
            <div className="flex justify-between items-center my-5">
                <MainTitle title="Evaluation Comparison" />
                <ButtonWrap contents={<FontAwesomeIcon icon={faArrowLeft} />} onClick={() => navigate('/')} />
            </div>
            <NewInfoFormat label="Testset" description={<></>} contents={<EvaluationTestsetSelector testset={testset} setTestset={setTestset} />} />
            {testset && <EvaluaitonComparisonColumns testset={testset} />}
        </div>)
}

export default EvaluationComparisonPage