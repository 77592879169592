import { useEffect, useState } from 'react';
import MainTitle from '../../../../components/template/MainTitle'
import ButtonWrap from '../../../../components/template/ButtonWrap'
import { useNavigate } from 'react-router-dom'
import { classNames } from '../../../../components/utils/Utils'
import api from '../../../../components/utils/api';
import LoadExistingThreshold from './LoadExistingThreshold';

const ThresholdNewModal = ({ generalModelAndMergeRule, existThresholds }) => {
    const [thresholds, setThresholds] = useState([])
    const [thresholdName, setThresholdName] = useState("")
    const [loadedThreshold, setLoadedThreshold] = useState({})

    const navigate = useNavigate()

    useEffect(() => {
        const recommendation = existThresholds.filter((e) => e.name === 'recommendation')
        const thresholdId = recommendation.length > 0 ? recommendation[0].id : existThresholds.filter((e) => e.name === 'default')[0].id
        api.get(`/api/post-action/merge-rules/${generalModelAndMergeRule.selectedMergeRule.id}/thresholds/${thresholdId}`)
            .then(res => setThresholds(res.data.values.map(e => ({ ...e.tag, value: e.value }))))
            .catch(err => console.error(err))
    }, [generalModelAndMergeRule, existThresholds])

    const onClick = () => {
        if (!thresholdName || thresholdName.length === 0) {
            alert('The threshold name is required')
            return;
        }
        api.post(`/api/post-action/merge-rules/${generalModelAndMergeRule.selectedMergeRule.id}/thresholds`, {
            name: thresholdName,
            general_model_id: generalModelAndMergeRule.generalModels.id,
            values: thresholds
        })
            .then(() => navigate(0))
            .catch(err => console.error(err))
    }

    useEffect(() => {
        if (Object.keys(loadedThreshold).length > 0) {
            const newThresholds = [...thresholds]
            Object.entries(loadedThreshold).forEach(([k, v]) => {
                const index = newThresholds.findIndex(e => e.name === k);
                if (index !== -1) {
                    newThresholds[index]['value'] = parseFloat(v)
                }
            })
            setThresholds([...newThresholds])
        }
    }, [loadedThreshold])

    return (
        <div>
            <div className='flex justify-between mt-3'>
                <MainTitle title="New Threshold" />
                <LoadExistingThreshold setLoadedThreshold={setLoadedThreshold} title={"Load Threshold"} />
            </div>
            <div className='flex items-center my-5'>
                <div className='font-bold mr-5'>Name</div>
                <hr />
                <input
                    type='text'
                    className="appearance-none w-full block bg-white text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none"
                    value={thresholdName}
                    onChange={(e) => setThresholdName(e.target.value)}
                    placeholder='Please set a name for this threshold set'
                />
            </div>
            <div className='md:grid md:grid-cols-2 gap-3 my-3'>
                {thresholds.map((e, i) => <div key={i} className='grid grid-cols-2 items-center'>
                    <label className={classNames(
                        'text-left font-bold text-sm truncate',
                        Object.keys(loadedThreshold).length > 0 && !(Object.keys(loadedThreshold).includes(e.name)) ? 'text-violet-500' : ''
                    )} htmlFor={e.name}>{e.name}</label>
                    <input
                        className="appearance-none w-full block bg-white text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none"
                        type='number'
                        step='0.01'
                        max='0.99'
                        min='0'
                        id={e.name}
                        value={e.value}
                        onChange={(evt) => {
                            const newThresholds = [...thresholds]
                            newThresholds[i]['value'] = parseFloat(evt.target.value)
                            setThresholds([...newThresholds])
                        }}
                    />
                </div>)}
            </div>
            <div className='text-right mt-3'>
                <ButtonWrap contents="Create" onClick={onClick} />
            </div>
        </div>
    )
}

export default ThresholdNewModal