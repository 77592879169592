import { classNames } from "../../components/utils/Utils";

const DBGenerationStatusPill = (props) => {
    const status = props.value ? props.value.toLowerCase() : "unknown";
    return (
        <span
            className={classNames(
                "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
                status.startsWith("error") ? "bg-red-100 text-red-700" : null,
                status.startsWith("generating") ? "bg-yellow-100 text-yellow-700" : null,
                status.startsWith("generated") ? "bg-green-100 text-green-700" : null,
            )}
        >{status.replaceAll("_", " ")}</span>
    );
}

export default DBGenerationStatusPill