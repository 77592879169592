import { useState, useMemo, useEffect } from "react";
import { useTable, useGlobalFilter, useAsyncDebounce, useFilters, useSortBy, usePagination, useRowSelect } from 'react-table'
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid'
import { Button, PageButton } from './shared/Button'
import { SortIcon, SortUpIcon, SortDownIcon } from './shared/Icons'
import { classNames } from "../utils/Utils";
import TextareaAutosize from 'react-textarea-autosize';
import ButtonWrap from "../template/ButtonWrap";

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <label className="flex gap-x-2 items-baseline">
            <span className="text-gray-700">Search: </span>
            <input
                type="text"
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 p-2"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
            />
        </label>
    )
}

export default function Table({
    columns,
    data,
    updateMyData = [],
    skipPageReset = true,
    skipFilterReset = true,
    skipSortReset = true,
    autoResetGlobalFilter = true,
    pageSize = 10,
    additionalButton = {},
    simpleVersion = false
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
    } =
        useTable({
            columns,
            data,
            initialState: { pageSize: pageSize },
            autoResetPage: !skipPageReset,
            autoResetFilters: !skipFilterReset,
            autoResetSortBy: !skipSortReset,
            autoResetGlobalFilter: autoResetGlobalFilter,
            updateMyData,
        },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination,
            useRowSelect,
        );

    return (
        <>
            <div className="flex justify-between">
                {!simpleVersion && <div className="flex gap-x-2">
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter} />
                    {headerGroups.map((headerGroup) =>
                        headerGroup.headers.map((column) =>
                            column.Filter ? (
                                <div key={column.id}>
                                    {column.render("Filter")}
                                </div>
                            ) : null
                        )
                    )}
                </div>}
                {Object.keys(additionalButton).length !== 0 && <ButtonWrap onClick={() => additionalButton.onClick(page)} contents={additionalButton.contents} />}
            </div>
            <div className="mt-2 flex flex-col">
                <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th
                                                    scope="col"
                                                    className="group px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                >
                                                    <div className="flex items-center justify-between">
                                                        {column.render('Header')}
                                                        {/* Add a sort direction indicator */}
                                                        <span>
                                                            {column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? <SortDownIcon className="w-4 h-4 text-gray-400" />
                                                                    : <SortUpIcon className="w-4 h-4 text-gray-400" />
                                                                : (
                                                                    <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                                                                )}
                                                        </span>
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody
                                    {...getTableBodyProps()}
                                    className="bg-white divide-y divide-gray-200"
                                >
                                    {page.map((page, i) => {
                                        prepareRow(page);
                                        return (
                                            <tr {...page.getRowProps()}>
                                                {page.cells.map((cell) => {
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className="px-4 py-4 text-left whitespace-pre-line"
                                                        >
                                                            {cell.render("Cell")}
                                                        </td>);
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-3 flex items-center justify-between">
                <div className="flex-1 flex justify-between sm:hidden">
                    <Button onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</Button>
                    <Button onClick={() => nextPage()} disabled={!canNextPage}>Next</Button>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                    {!simpleVersion && <div className="flex gap-x-2 items-baseline">
                        <span className="text-sm text-gray-700">
                            Page <span className="font-medium">{state.pageIndex + 1}</span> of <span className="font-medium">{pageOptions.length}</span>
                        </span>
                        <label>
                            <span className="sr-only">Items Per Page</span>
                            <select
                                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 py-2 pl-3 pr-6"
                                value={state.pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                            >
                                {[5, 10, 20].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <span className="flex items-center gap-1 text-sm text-gray-700">
                            Go to page:
                            <input
                                type="number"
                                defaultValue={state.pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(page)
                                }}
                                className="mt-1 w-2/5 block rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-black p-2"
                            />
                        </span>
                    </div>}
                    <div>
                        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                            <PageButton
                                className="rounded-l-md"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                <span className="sr-only">First</span>
                                <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </PageButton>
                            <PageButton
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                <span className="sr-only">Previous</span>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </PageButton>
                            <PageButton
                                onClick={() => nextPage()}
                                disabled={!canNextPage
                                }>
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                            </PageButton>
                            <PageButton
                                className="rounded-r-md"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                <span className="sr-only">Last</span>
                                <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                            </PageButton>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
};

export function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <label className="flex gap-x-2 items-baseline">
            <span className="text-gray-700">{render("Header")}: </span>
            <select
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 py-2 pl-3 pr-6"
                name={id}
                id={id}
                value={filterValue}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
            >
                <option value="">All</option>
                {options.map((option, i) => (
                    <option key={i} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </label>
    );
}

export function StatusPill(props) {
    const status = props.value ? props.value.toLowerCase() : "unknown";
    const isFeedback = props.row.original.status !== "accepting" && props.row.original.status !== "canceling";

    return (
        <>{isFeedback ?
            <span
                className={classNames(
                    "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
                    status.startsWith("created") ? "bg-blue-100 text-blue-700" : null,
                    status.startsWith("increased") ? "bg-green-100 text-green-700" : null,
                    status.startsWith("not changed") ? "bg-gray-100 text-gray-700" : null,
                    status.startsWith("decreased") ? "bg-yellow-100 text-yellow-700" : null,
                    status.startsWith("deleted") ? "bg-red-100 text-red-700" : null,

                    status.startsWith("modify") ? "bg-blue-100 text-blue-700" : null,
                    status.startsWith("delete") ? "bg-red-100 text-red-700" : null,
                    status.startsWith("add") ? "bg-green-100 text-green-700" : null,
                )}
            >
                {status}
            </span> :
            <span
                className={classNames(
                    "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm bg-slate-100",
                )}
            >
                {status}
            </span>
        }
        </>
    );
}

export function EditableCell({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData,
}) {
    const [value, setValue] = useState(initialValue)
    const onChange = e => {
        setValue(e.target.value)
    }
    const onBlur = () => {
        updateMyData(index, id, value)
    }
    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    return <TextareaAutosize value={value || ""} onChange={onChange} onBlur={onBlur} className="border-0 w-72 min-h-[140px]" />
}