const ButtonFull = ({ contents, onClick, isDisabled = false }) => {
    return (
        <button
            className="w-full bg-transparent hover:bg-gray-800 text-gray-700 font-semibold hover:text-white py-2 px-3 border border-gray-300 hover:border-transparent rounded disabled:opacity-25"
            onClick={onClick}
            disabled={isDisabled}
        >{contents}</button>
    )
}

export default ButtonFull