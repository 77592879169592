import { useMemo } from "react"
import Table from "../../components/table/Table"

const TrainingReportTableView = ({ data }) => {
    const columns = useMemo(
        () => [
            {
                Header: "Epoch",
                accessor: "epoch",
            },
            {
                Header: 'Loss',
                accessor: 'loss'
            },
            {
                Header: 'Precision',
                accessor: 'precision'
            },
            {
                Header: 'Recall',
                accessor: 'recall'
            },
            {
                Header: 'F-Score',
                accessor: 'fscore'
            },
        ], []
    )

    return (
        <Table data={data} columns={columns} />
    )
}

export default TrainingReportTableView