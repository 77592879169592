import { useParams } from "react-router-dom"
import MainTitle from "../../components/template/MainTitle"
import SubTitle from "../../components/template/SubTitle"
import { useEffect, useState } from "react"
import api from "../../components/utils/api"
import { classNames } from "../../components/utils/Utils"
import TrainingReportTableView from "./TrainingReportTableView"
import TrainingReportGraphView from "./TrainingReportGraphView"

const ReportModeSwitch = ({ isGraphView, setIsGraphView }) => (
    <div className="grid grid-cols-2 w-fit">
        {['Graph', 'Table'].map((e, i) => <button
            key={i}
            type="button"
            onClick={() => setIsGraphView(e === 'Graph')}
            className={classNames(
                'border border-l-0 border-gray-300 px-3 py-1',
                i === 0 ? 'rounded-l-full border-l' : 'rounded-r-full',
                (isGraphView === (e === 'Graph')) ? "text-violet-500 bg-violet-100" : "text-gray-500"
            )}
        >{e}</button>)}
    </div>
)

const ReportFormat = ({ isGraphView, title, data }) => data.length > 0 && (
    <div className="border rounded my-5 p-4">
        <div className="text-left text-xl font-bold">{title}</div>
        {isGraphView ? <TrainingReportGraphView data={data} /> : <TrainingReportTableView data={data} />}
    </div>
)

const TrainingReportPage = () => {
    const params = useParams()
    const [generalModel, setGeneralModel] = useState({})
    const [training, setTraining] = useState([])
    const [validation, setValidation] = useState([])
    const [isGraphView, setIsGraphView] = useState(true)

    useEffect(() => {
        if (params) {
            api.get(`/api/post-action/general-models/${params.id}`)
                .then(res => setGeneralModel(res.data))
                .catch(error => console.error(error))
            api.get(`/api/post-action/general-models/${params.id}/training-report`)
                .then(res => {
                    setTraining(res.data.training)
                    setValidation(res.data.validation)
                })
                .catch(error => console.error(error))
        }
    }, [params])

    return Object.keys(generalModel).length > 0 && (
        <div className='default-background'>
            <div className="flex items-end justify-between">
                <div>
                    <SubTitle title="Training Report" />
                    <MainTitle title={`${generalModel.name}`} />
                </div>
                <ReportModeSwitch isGraphView={isGraphView} setIsGraphView={setIsGraphView} />
            </div>
            {[
                { data: training, title: 'Training' },
                { data: validation, title: 'Validation' }
            ].map((e, i) => <ReportFormat data={e.data} title={e.title} isGraphView={isGraphView} key={i} />)}
        </div>
    )
}

export default TrainingReportPage