import { useState } from "react";
import api from "../../../../components/utils/api";
import ButtonWrap from "../../../../components/template/ButtonWrap";
import Modal from "../../../../components/template/Modal";
import LoadExistingData from "../../components/LoadExistingData";

const LoadExistingTimehlod = ({ setLoadedTimehold, title }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const openModal = () => { setModalVisible(true); }
    const closeModal = () => { setModalVisible(false); }

    const onClick = (selectedMergeRuleId, selectedTimeholdId) => {
        if (selectedMergeRuleId && selectedTimeholdId) {
            api.get(`/api/post-action/merge-rules/${selectedMergeRuleId}/timeholds/${selectedTimeholdId}`)
                .then(res => {
                    setLoadedTimehold(res.data.values.reduce((obj, e) => {
                        obj[e.tag.name] = e.value
                        return obj
                    }, {}))
                    closeModal()
                })
                .catch(err => console.error(err))
        }
    }

    return (
        <div className='mx-2'>
            <ButtonWrap contents={title} onClick={openModal} />
            {modalVisible && <Modal
                visible={modalVisible}
                closable={true}
                maskClosable={true}
                onClose={closeModal}>{
                    <LoadExistingData
                        title={title}
                        onClick={onClick}
                        dataSource={"timeholds"}
                    />
                }</Modal>}
        </div>
    )
}

export default LoadExistingTimehlod