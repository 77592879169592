import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { classNames } from '../utils/Utils';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal';

const ListSelector = ({ obejcts, selectedObject, onClick, modalContents, addNewString }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const HOVER_LIST_STYLE = "hover:bg-violet-100 hover:text-violet-500 py-1 px-2"
    const openModal = () => { setModalVisible(true); }
    const closeModal = () => { setModalVisible(false); }
    return (
        <>
            <ul className='border rounded py-3 overflow-y-auto h-full'>
                <li className={classNames(HOVER_LIST_STYLE, 'text-gray-500 cursor-pointer')} onClick={() => openModal()}>
                    <FontAwesomeIcon icon={faPlus} />{addNewString}
                </li>
                {obejcts.map((obj, i) => <li
                    key={i}
                    onClick={() => onClick(obj)}
                    vlaue={obj}
                    className={classNames(HOVER_LIST_STYLE, selectedObject.name && obj.name === selectedObject.name ? "bg-violet-100 text-violet-500 border-violet-500" : "cursor-pointer")}
                >{obj.name}</li>)}
            </ul>
            {modalVisible && <Modal
                visible={modalVisible}
                closable={true}
                maskClosable={false}
                onClose={closeModal}>{
                    modalContents
                }</Modal>}
        </>)
}

export default ListSelector