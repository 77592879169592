import TrainingReportLineChart from "./TrainingReportLineChart";

const TrainingReportGraphView = ({ data }) => {
    const transformData = (data) => {
        let loss_data = [{ x: [], y: [], mode: 'lines', name: 'Loss' }]
        let others_data = ['Precision', 'Recall', 'F-Score'].map(e => ({ x: [], y: [], mode: 'lines', name: e }))
        data.forEach(e => {
            loss_data[0].x.push(e.epoch);
            loss_data[0].y.push(e.loss);
            others_data[0].x.push(e.epoch);
            others_data[0].y.push(e.precision);
            others_data[1].x.push(e.epoch);
            others_data[1].y.push(e.recall);
            others_data[2].x.push(e.epoch);
            others_data[2].y.push(e.fscore);
        });
        return [
            { name: 'Loss', data: loss_data },
            { name: 'Precision & Recall & F-Score', data: others_data }
        ];
    }

    return data && (
        <div>
            {transformData(data).map((e, i) => (<TrainingReportLineChart name={e.name} data={e.data} key={i} />))}
        </div>
    )
}

export default TrainingReportGraphView