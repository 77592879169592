import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const DirectLink = ({ path, title }) => (
    <a
        href={path}
        target='_blank'
        rel="noopener noreferrer"
        title={title}
        className="text-gray-700 hover:text-purple-500"
    >
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="lg" />
    </a>
)

export default DirectLink