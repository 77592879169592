import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Table from '../../../../components/table/Table';
import Modal from '../../../../components/template/Modal';
import ServiceNewModal from './ServiceNewModal';
import DirectLink from '../../../../components/template/DirectLink';
import Spinner from '../../../../components/template/Spinner';
import api from '../../../../components/utils/api';

const ServicePage = () => {
    const { state } = useLocation();
    const [modalVisible, setModalVisible] = useState(false);
    const [data, setData] = useState([])
    const openModal = () => { setModalVisible(true); }
    const closeModal = () => { setModalVisible(false); }

    useEffect(() => {
        if (state) {
            api.get(`/api/post-action/merge-rules/${state.selectedMergeRule.id}/services?general_model_id=${state.generalModels.id}`)
                .then(res => setData(res.data))
                .catch(err => console.error(err))
        }
    }, [state])

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
            },
            {
                Header: "Group",
                accessor: "group_name",
            },
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Model Type",
                accessor: "model_type_col",
                Cell: ({ row }) => <div>{row.original.model_type.join(", ")}</div>
            },
            {
                Header: "Converted Model",
                accessor: "converted_model_col",
                Cell: ({ row }) => <div>{row.original.converted_model.name}</div>
            },
            {
                Header: "Google Storage",
                accessor: "path",
                Cell: ({ row }) => (<>{
                    row.original.status === 1 ?
                        <DirectLink path={`https://console.cloud.google.com/storage/browser/${row.original.path}`} title={`Direct link to the google storage link for ${row.original.name} service of ${row.original.group_name}`} />
                        : <div className='h-10 w-32 text-gray-500 border border-gray-400 rounded text-xs m-auto flex'>
                            <div className='pt-2 pl-2'><Spinner /></div>
                            <div className='m-auto cursor-default'>Exporting...</div>
                        </div>
                }</>)
            },
            {
                Header: "Deploy",
                accessor: "deployment",
                Cell: ({ row }) => (<>{
                    row.original.status === 1 ?
                        <DirectLink path={`${process.env.REACT_APP_BIZ_DOMAIN}/models?service_id=${row.original.id}`} title={`Direct link for the model deployment`} />
                        : <div className='h-10 w-32 text-gray-500 border border-gray-400 rounded text-xs m-auto flex'>
                            <div className='pt-2 pl-2'><Spinner /></div>
                            <div className='m-auto cursor-default'>Exporting...</div>
                        </div>
                }</>)
            },
            {
                Header: "Threshold",
                accessor: "threshold_col",
                Cell: ({ row }) => <div>{row.original.threshold.name}</div>
            },
            {
                Header: "Timehold",
                accessor: "timehold_col",
                Cell: ({ row }) => <div>{row.original.timehold.name}</div>
            },

        ], []
    )

    return (
        <div>
            <Table data={data} columns={columns} additionalButton={{ contents: "New Service", onClick: () => openModal() }} />
            {modalVisible && <Modal
                visible={modalVisible}
                closable={true}
                maskClosable={true}
                onClose={closeModal}>{
                    <ServiceNewModal state={state} closeModal={closeModal} />
                }</Modal>}
        </div>
    )
}

export default ServicePage