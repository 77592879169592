import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MergeRuleSelector from './MergeRuleSelector'
import SubTitle from '../../components/template/SubTitle';
import { classNames, replacePeriod } from '../../components/utils/Utils';
import { Link, Outlet } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBoxesPacking, faChartLine, faClock, faGear, faListOl, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import MainTitle from '../../components/template/MainTitle';
import ButtonWrap from '../../components/template/ButtonWrap';
import api from '../../components/utils/api';


const PostActionPage = () => {
    const { state } = useLocation();
    const [generalModels, setGeneralModels] = useState([])
    const [mergeRules, setMergeRules] = useState([])
    const [selectedMergeRule, setSelectedMergeRule] = useState({})
    const [isReportDisabled, setIsReportDisabled] = useState(true)

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (state) {
            if (Object.keys(state).includes('selectedMergeRule')) setSelectedMergeRule(state.selectedMergeRule)
            api.get(`/api/post-action/general-models?db_ver=${replacePeriod(state.dbVersion)}&exp_ver=${state.expVersion}&model_name=${replacePeriod(`${state.dbVersion}_${state.expVersion}_${state.modelType}`)}`)
                .then(res => setGeneralModels(res.data.general_models))
                .catch(err => {
                    console.error(err)
                    // TODO: update error handling
                    if (err.response.status === 404) {
                        alert(err.response.data.message + " Return to the model version selection page.")
                        navigate('/')
                    }
                })
        }
    }, [state, navigate])

    useEffect(() => {
        if (generalModels.length) {
            api.get(`/api/post-action/experiments/${generalModels[0].experiment_id}`)
                .then(experiment => { setMergeRules(experiment.data.merge_rules) })
            setIsReportDisabled(generalModels[0].model_type.includes("Ensemble"))
        }
    }, [generalModels])

    return (
        <div className='default-background'>
            <div className='mb-5 md:flex items-end justify-between'>
                <div className='md:flex items-center'>
                    <div className='mr-2'>
                        <SubTitle title="Post Action" />
                        <MainTitle title={`${state.dbVersion}_${state.expVersion}_${state.modelType}`} />
                    </div>
                    <div className='mt-6'>
                        {!isReportDisabled && <Link
                            to={`/training-report/${generalModels[0].id}`}
                            target="_blank" rel="noopener noreferrer"
                            className='bg-violet-100 text-sm border border-violet-500 text-violet-500 hover:bg-violet-200 px-2 py-1 rounded-xl'
                        >Training Report</Link>}
                    </div>
                </div>
                <div className='text-left'>
                    <ButtonWrap contents={<FontAwesomeIcon icon={faArrowLeft} />} onClick={() => navigate('/model-selection')} />
                </div>
            </div>

            <SubTitle title="Merge Rules" />
            <div className={classNames('py-2 grid gap-3 ', selectedMergeRule.hasOwnProperty('id') ? "md:grid-cols-5 md:h-[80vh]" : "")}>
                <MergeRuleSelector
                    mergeRules={mergeRules}
                    selectedMergeRule={selectedMergeRule}
                    generalModel={{ ...state, generalModels: generalModels[0] }}
                />
                {selectedMergeRule.hasOwnProperty('id') && <div className='md:col-span-4'>
                    <div className='rounded border border-gray-200 h-full min-h-[300px] max-h-[80vh] p-5 overflow-auto'>
                        <ul className='grid grid-cols-6 mb-6 text-sm md:text-base'>
                            {[
                                { title: 'detail', icon: faNewspaper },
                                { title: 'convert', icon: faGear },
                                { title: 'threshold', icon: faListOl },
                                { title: 'evaluation', icon: faChartLine },
                                { title: 'timehold', icon: faClock },
                                { title: 'service', icon: faBoxesPacking }
                            ].map((e, i) => <li key={i} className={classNames(e.title === location.pathname.split("/").pop() ? "text-violet-500" : "text-gray-500")}>
                                <Link to={e.title} state={{ ...state, selectedMergeRule: selectedMergeRule, generalModels: generalModels[0] }}><FontAwesomeIcon icon={e.icon} /><br />{e.title[0].toUpperCase() + e.title.slice(1)}</Link>
                            </li>)}
                        </ul>
                        <Outlet />
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default PostActionPage