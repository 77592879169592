import { useEffect, useState } from 'react';
import Select from 'react-select'
import axios from 'axios'
import ButtonWrap from '../../components/template/ButtonWrap'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const Selector = ({ label, options, value, setValue, noOptionsMessage }) => (
    <div>
        <p className='text-xs font-bold text-left text-gray-800 py-2'>{label}</p>
        <Select
            options={options}
            value={{ label: value, value: value }}
            onChange={(e) => setValue(e.value)}
            noOptionsMessage={() => noOptionsMessage}
        />
    </div>
)

const ModelSelectionPage = () => {
    const [dbOptions, setDbOptions] = useState([])
    const [expOptions, setExpOptions] = useState([])
    const [modelOptions, setModelOptions] = useState([])

    const [dbVersion, setDbVersion] = useState()
    const [expVersion, setExpVersion] = useState()
    const [modelType, setModelType] = useState()

    const navigate = useNavigate()

    useEffect(() => {
        axios.get('/api/model-selection/dbs')
            .then(res => setDbOptions(res.data.dbs))
            .catch(e => console.error(e))
    }, [])

    useEffect(() => {
        if (dbVersion) {
            setExpVersion()
            axios.get(`/api/model-selection/experiments?db_ver=${dbVersion}`)
                .then(res => setExpOptions(res.data.experiments))
                .catch(e => console.error(e))
        }
    }, [dbVersion])

    useEffect(() => {
        if (expVersion) {
            setModelType()
            axios.get(`/api/model-selection/model-types?db_ver=${dbVersion}&exp_ver=${expVersion}`)
                .then(res => setModelOptions(res.data.model_types))
                .catch(e => console.error(e))
        }
    }, [dbVersion, expVersion])
    return (
        <div className='default-background'>
            <div className='text-right'>
                <ButtonWrap contents={<FontAwesomeIcon icon={faArrowLeft} />} onClick={() => navigate('/')} />
            </div>
            <div className='md:px-36 px-12 absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-full'>
                <div className='md:grid md:grid-cols-4'>
                    <div className='self-center py-2'>
                        <img src='eats_logo.png' alt='logo' className='m-auto' />
                    </div>
                    <div className='md:col-span-3 mt-10 md:mt-0'>
                        {[
                            { label: "Database Version", options: dbOptions, value: dbVersion, setValue: setDbVersion, noOptionsMessage: "Please wait..." },
                            { label: "Experiment Number", options: expOptions, value: expVersion, setValue: setExpVersion, noOptionsMessage: "No experiment available" },
                            { label: "Model Type", options: modelOptions, value: modelType, setValue: setModelType, noOptionsMessage: "No model type available" },
                        ].map((e, i) => <Selector
                            label={e.label}
                            options={e.options.map(e => ({ label: e, value: e }))}
                            value={e.value}
                            setValue={e.setValue}
                            noOptionsMessage={e.noOptionsMessage}
                            key={i}
                        />)}
                    </div>
                </div>
                <div className='mt-5 text-right'>
                    <ButtonWrap contents="Next" onClick={() => {
                        navigate('/post-action', {
                            state: {
                                dbVersion: dbVersion,
                                expVersion: expVersion,
                                modelType: modelType,
                            }
                        })
                    }} isDisabled={!(dbVersion && expVersion && modelType)} />
                </div>
            </div>
        </div>
    )
}

export default ModelSelectionPage
