import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "../utils/Utils";

const SubStep = ({ subStep }) => (
    <div
        className={classNames("flex items-center gap-2 py-2",
            subStep.subStepStatus === 'inprogress' ? 'text-purple-500 animate-pulse' : subStep.subStepStatus === 'done' ? 'text-purple-800' : 'text-gray-400'
        )}>
        <FontAwesomeIcon icon={subStep.subStepStatus === 'inprogress' ? faSpinner : faCheck} className={classNames(subStep.subStepStatus === 'inprogress' ? 'animate-spin' : '')} /> <p>{subStep.message}</p>
    </div>
)

const Step = ({ stepNumber, title, stepStatus, subSteps, isLast }) => (
    <div className="relative flex items-start">
        <div className="flex flex-col items-center">
            <div className={classNames(
                "w-8 h-8 rounded-full flex items-center justify-center z-10 border-2",
                stepStatus === 'done' ? 'bg-purple-500 text-white border-purple-500' : 'bg-white text-gray-400 border-gray-300'
            )}>{stepStatus === 'done' ? <FontAwesomeIcon icon={faCheck} /> : stepNumber}
            </div>
            {!isLast && (
                <div className="absolute top-8 left-4 w-0.5 h-full bg-gray-300"></div>
            )}
        </div>
        <div className="ml-8">
            <h3 className={classNames("text-lg font-medium", stepStatus === 'inprogress' ? 'text-purple-500' : stepStatus === 'done' ? 'text-purple-800' : 'text-gray-400')}>{title}</h3>
            <div className="h-2"></div>
            {subSteps.map((subStep, i) => <SubStep subStep={subStep} key={i} />)}
            <div className="h-4"></div>
        </div>
    </div>
);

const VerticalStepper = ({ steps }) => (
    <div className="relative flex flex-col items-start text-left">
        {steps.map((step, index) => (
            <Step
                key={index}
                stepNumber={index + 1}
                title={step.title}
                stepStatus={step.stepStatus}
                subSteps={step.subSteps}
                isLast={index === steps.length - 1}
            />
        ))}
    </div>
);

export default VerticalStepper;