import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import MergeRulePairTemplate from "./MergeRulePairTemplate"
import MainTitle from "../../../components/template/MainTitle"
import api from "../../../components/utils/api"

const LoadExistingMergeRuleDetail = () => {
    const params = useParams()
    const [mergeRule, setMergeRule] = useState([])

    useEffect(() => {
        api.get(`/api/post-action/merge-rules/${params.id}`)
            .then(res => setMergeRule(res.data))
            .catch(e => console.error(e))
    }, [params])

    return (
        <div className='default-background'>
            <MainTitle title={mergeRule.name} />
            {mergeRule.length !== 0 && mergeRule.tags.map((rep, i) => <MergeRulePairTemplate
                key={i}
                representativeClass={rep.name}
                pairedClasses={rep.merged_classes}
            />)}
        </div>
    )
}

export default LoadExistingMergeRuleDetail