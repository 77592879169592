import { useEffect, useState } from 'react';
import MainTitle from '../../../../components/template/MainTitle'
import ButtonWrap from '../../../../components/template/ButtonWrap'
import { useNavigate } from 'react-router-dom'
import { classNames } from '../../../../components/utils/Utils'
import api from '../../../../components/utils/api';
import LoadExistingTimehlod from './LoadExistingTimehlod';

const TimeholdInput = ({ k, v, type, onChange }) => (
    <input
        className="appearance-none w-full block bg-white text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none"
        type='number'
        step='1'
        min={0}
        id={`${k}_${type}`}
        value={v || 0}
        onChange={onChange}
    />
)

const TimeholdNewModal = ({ generalModelAndMergeRule }) => {
    const [timeholds, setTimeholds] = useState([])
    const [timeholdName, setTimeholdName] = useState("")
    const [loadedTimehold, setLoadedTimehold] = useState({})

    const navigate = useNavigate()

    useEffect(() => {
        api.get(`/api/post-action/merge-rules/${generalModelAndMergeRule.selectedMergeRule.id}`)
            .then(res => {
                setTimeholds(res.data.tags.map(e => ({ ...e, value: Object.fromEntries(['n_prev_05', 'n_pass_05', 'n_prev_10', 'n_pass_10'].map(timeholdOption => ([timeholdOption, 0]))) })))
            }).catch(err => console.error(err))
    }, [generalModelAndMergeRule])

    const onClick = () => {
        if (!timeholdName || timeholdName.length === 0) {
            alert('The timehold name is required')
            return;
        }
        api.post(`/api/post-action/merge-rules/${generalModelAndMergeRule.selectedMergeRule.id}/timeholds`, {
            name: timeholdName,
            general_model_id: generalModelAndMergeRule.generalModels.id,
            values: timeholds
        })
            .then(() => navigate(0))
            .catch(err => console.error(err))
    }

    useEffect(() => {
        if (Object.keys(loadedTimehold).length > 0) {
            const newTimeholds = [...timeholds]
            Object.entries(loadedTimehold).forEach(([k, v]) => {
                if (newTimeholds.findIndex(e => e.name === k) !== -1) {
                    Object.entries(v).forEach(([k2, v2]) => {
                        newTimeholds[newTimeholds.findIndex(e => e.name === k)]['value'][k2] = parseInt(v2)
                    })
                }
            })
            setTimeholds(newTimeholds)
        }
    }, [loadedTimehold])

    return (
        <div>
            <div className='flex justify-between mt-3'>
                <MainTitle title="New Timehold" />
                <LoadExistingTimehlod setLoadedTimehold={setLoadedTimehold} title={"Load Timehold"} />
            </div>
            <div className='flex items-center my-5'>
                <div className='font-bold mr-5'>Name</div>
                <hr />
                <input
                    type='text'
                    className="appearance-none w-full block bg-white text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none"
                    value={timeholdName}
                    onChange={(e) => setTimeholdName(e.target.value)}
                    placeholder='Please set a name for this timehold set'
                />
            </div>
            <div className='grid grid-cols-5 font-bold'>
                <div></div>
                <div>nprev_0.5</div>
                <div>npass_0.5</div>
                <div>nprev_1</div>
                <div>npass_1</div>
            </div>
            {timeholds.map((e, i) => <div key={i} className='grid grid-cols-5 gap-2 items-center my-2'>
                <div className={classNames(
                    'text-left font-bold text-sm truncate',
                    Object.keys(loadedTimehold).length > 0 && !(Object.keys(loadedTimehold).includes(e.name)) ? 'text-violet-500' : ''
                )}>{e.name}</div>
                {Object.entries(e.value).map(([k, v], idx) => <TimeholdInput
                    key={idx}
                    k={e.name}
                    v={v}
                    type={k}
                    onChange={(evt) => {
                        const newTimeholds = [...timeholds]
                        newTimeholds[i]['value'][k] = parseInt(evt.target.value)
                        setTimeholds(newTimeholds)
                    }}
                />)}
            </div>)}
            <div className='text-right mt-3'>
                <ButtonWrap contents="Create" onClick={onClick} />
            </div>
        </div>
    )
}

export default TimeholdNewModal