import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ListSelector from '../../../../components/template/ListSelector';
import TimeholdModal from './TimeholdModal';
import Modal from '../../../../components/template/Modal';
import TimeholdNewModal from './TimeholdNewModal';
import api from '../../../../components/utils/api';

const TimeholdPage = () => {
    const { state } = useLocation();
    const [timeholds, setTimeholds] = useState([])
    const [selectedTimehold, setSelectedTimehold] = useState({})
    const [modalVisible, setModalVisible] = useState(false);
    const openModal = () => { setModalVisible(true); }
    const closeModal = () => { setModalVisible(false); }

    useEffect(() => {
        if (state) {
            api.get(`/api/post-action/merge-rules/${state.selectedMergeRule.id}/timeholds?general_model_id=${state.generalModels.id}`)
                .then(res => setTimeholds(res.data.timeholds))
                .catch(err => console.error(err))
        }
    }, [state])

    return (
        <>
            <ListSelector
                obejcts={timeholds}
                selectedObject={selectedTimehold}
                onClick={(obj) => { setSelectedTimehold(obj); openModal() }}
                modalContents={<TimeholdNewModal generalModelAndMergeRule={state} />}
                addNewString={' Add a Timehold'}
            />
            {modalVisible && <Modal
                visible={modalVisible}
                closable={true}
                maskClosable={true}
                onClose={closeModal}>{
                    <TimeholdModal selectedTimehold={selectedTimehold} mergeRuleId={state.selectedMergeRule.id} />
                }</Modal>}
        </>
    )
}

export default TimeholdPage