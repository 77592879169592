import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ListSelector from '../../../../components/template/ListSelector';
import Modal from '../../../../components/template/Modal';
import ThresholdModal from './ThresholdModal';
import ThresholdNewModal from './ThresholdNewModal';
import api from '../../../../components/utils/api';

const ThresholdPage = () => {
    const { state } = useLocation();
    const [thresholds, setThresholds] = useState([])
    const [selectedThreshold, setSelectedThreshold] = useState({})
    const [modalVisible, setModalVisible] = useState(false);
    const openModal = () => { setModalVisible(true); }
    const closeModal = () => { setModalVisible(false); }

    useEffect(() => {
        if (state) {
            api.get(`/api/post-action/merge-rules/${state.selectedMergeRule.id}/thresholds?general_model_id=${state.generalModels.id}`)
                .then(res => setThresholds(res.data.thresholds))
                .catch(err => console.error(err))
        }
    }, [state])

    return (
        <>
            <ListSelector
                obejcts={thresholds}
                selectedObject={selectedThreshold}
                onClick={(obj) => { setSelectedThreshold(obj); openModal() }}
                modalContents={<ThresholdNewModal generalModelAndMergeRule={state} existThresholds={thresholds} />}
                addNewString={' Add a Threshold'}
            />
            {modalVisible && <Modal
                visible={modalVisible}
                closable={true}
                maskClosable={true}
                onClose={closeModal}>{
                    <ThresholdModal selectedThreshold={selectedThreshold} mergeRuleId={state.selectedMergeRule.id} />
                }</Modal>}
        </>
    )
}

export default ThresholdPage