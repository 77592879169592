import Plot from 'react-plotly.js';

const TrainingReportLineChart = ({ data, name }) => {
    const layout = {
        title: '',
        xaxis: {
            title: 'Epoch',
        },
        yaxis: {
            side: 'left'
        },
        showlegend: true,
        legend: {
            yanchor: 'top',
            xanchor: 'left',
        },
        margin: {
            t: 5,
        },
        height: 500,
        autosize: true
    }
    const config = { scrollZoom: false, displayModeBar: false, responsive: true, }

    return (
        <div className='m-1'>
            <div className="text-left text-lg text-gray-500 mx-5 my-3">{name}</div>
            <Plot
                data={data}
                layout={layout}
                config={config}
                style={{ width: "100%" }}
            />
        </div>
    )
}

export default TrainingReportLineChart