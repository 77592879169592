import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const Modal = ({
    className,
    onClose,
    maskClosable,
    closable,
    visible,
    children,
    help = true
}) => {

    function onMaskClick(e) {
        if (e.target === e.currentTarget) {
            onClose(e)
        }
    }

    function close(e) {
        if (onClose) {
            onClose(e)
        }
    }
    return (
        <>
            <ModalOverlay visible={visible} />
            <ModalWrapper
                className={className}
                onClick={maskClosable ? onMaskClick : null}
                tabIndex="-1"
                visible={visible}
            >
                <ModalInner tabIndex="0" className="modal-inner" help={help}>
                    {closable && <button className="absolute top-7 right-10" onClick={close}><FontAwesomeIcon icon={faXmark} /></button>}
                    {children}
                </ModalInner>
            </ModalWrapper>
        </>
    )
}


Modal.propTypes = {
    visible: PropTypes.bool,
    help: PropTypes.bool,
}

Modal.defaultProps = {
    closable: true,
    maskClosable: true,
    visible: false,
    help: true,
}

const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 10px;
  width: 80%;
  height: 80%;
  max-width: 100%;
  top: 50%;
  transform: translateY(-50%);
  max-height: 90%;
  margin: 0 auto;
  padding: 35px;
  overflow: auto
`

export default Modal