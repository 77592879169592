import { useCallback, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import api from "../../components/utils/api"
import MainTitle from "../../components/template/MainTitle"
import TrainingStatusPill from "./TrainingStatusPill"
import ButtonWrap from "../../components/template/ButtonWrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRotateRight } from "@fortawesome/free-solid-svg-icons"
import TraceStepper from "../../components/template/TraceStepper"

const TrainingDetail = () => {
    const [experiment, setExperiment] = useState({})
    const [traceCode, setTraceCode] = useState(201)

    const params = useParams()
    const navigate = useNavigate()

    const fetchTrace = useCallback(() => {
        api.get(`/api/training/experiments/${params.id}/traces`)
            .then(res => setTraceCode(res.data.experiment_traces.length > 0 ? res.data.experiment_traces[0].trace_code : 200))
            .catch(error => console.error(error))
    }, [params])

    useState(() => {
        api.get(`/api/training/experiments/${params.id}`)
            .then(res => setExperiment(res.data.experiment))
            .catch(error => console.error(error))
        fetchTrace()
    }, [params])

    const defaultSteps = useMemo(() => [
        { id: 0, title: 'Preaction process started', stepStatus: 'waiting', subSteps: [] },
        {
            id: 1, title: 'Resource preparation', stepStatus: 'waiting', subSteps: [
                { id: 0, message: 'Resources preparation started', subStepStatus: 'waiting' },
                { id: 1, message: 'Get CochlDB and Cochlogy version', subStepStatus: 'waiting' },
                { id: 2, message: 'Get class hierarchy', subStepStatus: 'waiting' },
                { id: 3, message: 'Get class filter', subStepStatus: 'waiting' },
                { id: 4, message: 'Get benchmark merge rule', subStepStatus: 'waiting' },
                { id: 5, message: 'Get CochlDB records', subStepStatus: 'waiting' },
                { id: 9, message: 'Resources preparation completed', subStepStatus: 'waiting' },
            ]
        },
        {
            id: 2, title: 'Data table creation', stepStatus: 'waiting', subSteps: [
                { id: 0, message: 'Data table creation started', subStepStatus: 'waiting' },
                { id: 1, message: 'Exclude classes with insufficient data', subStepStatus: 'waiting' },
                { id: 2, message: 'Limit class data to ensure balance', subStepStatus: 'waiting' },
                { id: 3, message: 'Apply class hierarchy and filter', subStepStatus: 'waiting' },
                { id: 4, message: 'Apply benchmark merge rules', subStepStatus: 'waiting' },
                { id: 5, message: 'Data table creation', subStepStatus: 'waiting' },
                { id: 9, message: 'Data table creation completed', subStepStatus: 'waiting' },
            ]
        },
        { id: 9, title: 'Preaction process completed', stepStatus: 'waiting', subSteps: [] },
    ], [])

    return (
        <div className='default-background'>
            {experiment.hasOwnProperty('id') && <div>
                <div className="flex justify-between items-center my-5">
                    <div className="flex items-center">
                        <MainTitle title={`${experiment.cochl_db.name}_${experiment.exp_ver}`} />
                        <div className="mx-3"><TrainingStatusPill value={experiment.status.name} /></div>
                    </div>
                    <div className="grid grid-cols-2 gap-2 items-center">
                        {experiment.status.value === 0 && <ButtonWrap contents={<FontAwesomeIcon icon={faArrowRotateRight} />} onClick={() => fetchTrace()} />}
                        <ButtonWrap contents={<FontAwesomeIcon icon={faArrowLeft} />} onClick={() => navigate('/training')} />
                    </div>
                </div>
                <TraceStepper defaultSteps={defaultSteps} traceCode={experiment.status.value === 0 ? traceCode : 299} />
            </div>}
        </div>
    )
}

export default TrainingDetail