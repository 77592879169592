import { useEffect, useState } from "react";
import ButtonWrap from "../../../components/template/ButtonWrap";
import Modal from "../../../components/template/Modal";
import Select from 'react-select';
import MainTitle from "../../../components/template/MainTitle";
import api from "../../../components/utils/api";
import SubTitle from "../../../components/template/SubTitle";

const LoadBenchmarkTemplateSelector = ({ value, setValue, onLoad }) => {
    const [options, setOptions] = useState([])

    useEffect(() => {
        api.get('/api/benchmark-datasets/all')
            .then(res => setOptions(res.data.benchmark_datasets.map(e => ({ label: e.name, value: e.id, classes: e.benchmark_dataset_classes }))))
            .catch(error => console.error(error))
    }, [])

    return (
        <div className="grid gap-2">
            <MainTitle title={'Load Template'} />
            <SubTitle title="Benchmark Datasets" />
            <p className="text-left text-sm text-gray-500">To evaluate a model with a benchmark dataset, you need a merge rule based on the template.</p>
            <Select
                options={options}
                value={value}
                onChange={setValue}
            />
            <div className="text-right">
                <ButtonWrap contents={'Load'} onClick={onLoad} isDisabled={!(value && value.hasOwnProperty('classes'))} />
            </div>
        </div>

    )
}

const LoadBenchmarkTemplate = ({ setMergeRuleName, setMergeRulePairs }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [benchmarkDataset, setBenchmarkDataset] = useState()
    const openModal = () => { setModalVisible(true); }
    const closeModal = () => { setModalVisible(false); }
    const onLoad = () => {
        setMergeRuleName(`${benchmarkDataset.label}_`)
        setMergeRulePairs(benchmarkDataset.classes.map((e, i) => ({ id: i, tagName: e, mergedClasses: [] })))
        closeModal()
    }

    return (
        <div className="mr-2">
            <ButtonWrap contents={'Load Template'} onClick={openModal} />
            {modalVisible && <Modal
                visible={modalVisible}
                closable={true}
                maskClosable={true}
                onClose={closeModal}>{
                    <div><LoadBenchmarkTemplateSelector
                        value={benchmarkDataset}
                        setValue={setBenchmarkDataset}
                        onLoad={onLoad}
                    /></div>
                }</Modal>}
        </div>
    )
}

export default LoadBenchmarkTemplate