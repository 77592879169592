import Select from 'react-select'

const NewInfoSelector = ({ value, setValue, options, category, noOptionsMessage = undefined }) => (
    <Select
        options={options}
        value={value}
        onChange={setValue}
        noOptionsMessage={() => noOptionsMessage ? noOptionsMessage : `Please create at least one ${category} before exporting a service`}
    />
)

export default NewInfoSelector