import { useEffect, useState } from "react";
import { classNames } from "../../components/utils/Utils";
import { getComparison } from "./utils";
import SubTitle from "../../components/template/SubTitle";

const getMergedEvaluation = (arr1, arr2) => {
    const map1 = new Map();
    const map2 = new Map();

    // Store each item of arr1 and arr2 in a Map, using 'class' as the key
    arr1.forEach(item => map1.set(item.merge_rule_tags.name, item));
    arr2.forEach(item => map2.set(item.merge_rule_tags.name, item));

    // Store all class names in a Set to remove duplicates
    const allClasses = new Set([...map1.keys(), ...map2.keys()]);
    const sortedClasses = Array.from(allClasses).sort()

    // Initialize an array to store the result
    const mergedArray = [];
    sortedClasses.forEach(cls => {
        mergedArray.push({
            className: cls,
            model1: map1.get(cls) || {},  // Return an empty object if the class is not found
            model2: map2.get(cls) || {}   // Return an empty object if the class is not found
        });
    });

    return mergedArray;
};


const EvaluationComparisonInternalResultColumn = ({ evaluations, modelKey }) => (
    <div className="px-2">
        <div className="text-lg mb-2">
            <SubTitle title="Results by class" />
        </div>
        <div className='grid grid-cols-6 items-center border-y font-semibold'>
            {["Tag", "F-score", "Precision", "Recall", "AP"].map((e, i) => <div key={i} className={classNames('py-3', i === 0 ? "col-span-2" : "")}>{e}</div>)}
        </div>
        {evaluations.map((evaluation, i) =>
            <div key={i} className={classNames(
                'grid grid-cols-6 py-3 border-b border-gray-200',
                evaluation[modelKey].f_score === undefined && 'text-gray-300'
            )}>
                <div className="font-semibold col-span-2 truncate" title={evaluation.className}>{evaluation.className}</div>
                <div className={classNames(getComparison(evaluation[modelKey].f_score, evaluation[modelKey === 'model1' ? 'model2' : 'model1'].f_score))}>{evaluation[modelKey].f_score !== undefined ? evaluation[modelKey].f_score : '-'}</div>
                <div className={classNames(getComparison(evaluation[modelKey].precision, evaluation[modelKey === 'model1' ? 'model2' : 'model1'].precision))}>{evaluation[modelKey].precision !== undefined ? evaluation[modelKey].precision : '-'}</div>
                <div className={classNames(getComparison(evaluation[modelKey].recall, evaluation[modelKey === 'model1' ? 'model2' : 'model1'].recall))}>{evaluation[modelKey].recall !== undefined ? evaluation[modelKey].recall : '-'}</div>
                <div className={classNames(getComparison(evaluation[modelKey].ap, evaluation[modelKey === 'model1' ? 'model2' : 'model1'].ap))}>{evaluation[modelKey].ap !== undefined ? evaluation[modelKey].ap : '-'}</div>
            </div>
        )}
    </div>
);

const EvaluationComparisonEsc50ResultColumn = ({ evaluations, modelKey }) => (
    <div className="px-2">
        <div className="text-lg mb-2">
            <SubTitle title="Results by class" />
        </div>
        <div className='grid grid-cols-6 items-center border-y font-semibold'>
            {["Tag", "Accuracy", "F-score", "Precision", "Recall"].map((e, i) => <div key={i} className={classNames('py-3', i === 0 ? "col-span-2" : "")}>{e}</div>)}
        </div>
        {evaluations.map((evaluation, i) =>
            <div key={i} className={classNames(
                'grid grid-cols-6 py-3 border-b border-gray-200',
                evaluation[modelKey].f_score === undefined && 'text-gray-300'
            )}>
                <div className="font-semibold col-span-2 truncate" title={evaluation.className}>{evaluation.className}</div>
                <div className={classNames(getComparison(evaluation[modelKey].accuracy, evaluation[modelKey === 'model1' ? 'model2' : 'model1'].accuracy))}>{evaluation[modelKey].accuracy !== undefined ? evaluation[modelKey].accuracy : '-'}</div>
                <div className={classNames(getComparison(evaluation[modelKey].f_score, evaluation[modelKey === 'model1' ? 'model2' : 'model1'].f_score))}>{evaluation[modelKey].f_score !== undefined ? evaluation[modelKey].f_score : '-'}</div>
                <div className={classNames(getComparison(evaluation[modelKey].precision, evaluation[modelKey === 'model1' ? 'model2' : 'model1'].precision))}>{evaluation[modelKey].precision !== undefined ? evaluation[modelKey].precision : '-'}</div>
                <div className={classNames(getComparison(evaluation[modelKey].recall, evaluation[modelKey === 'model1' ? 'model2' : 'model1'].recall))}>{evaluation[modelKey].recall !== undefined ? evaluation[modelKey].recall : '-'}</div>
            </div>
        )}
    </div>
);

const EvaluationComparisonDcase2018Task2ResultColumn = ({ evaluations, modelKey }) => (
    <div className="px-2">
        <div className="text-lg mb-2">
            <SubTitle title="Results by class" />
        </div>
        <div className='grid grid-cols-2 items-center border-y font-semibold'>
            {["Tag", "Score"].map((e, i) => <div key={i} className={classNames('py-3')}>{e}</div>)}
        </div>
        {evaluations.map((evaluation, i) =>
            <div key={i} className={classNames(
                'grid grid-cols-2 py-3 border-b border-gray-200',
                evaluation[modelKey].score === undefined && 'text-gray-300'
            )}>
                <div className="font-semibold truncate" title={evaluation.className}>{evaluation.className}</div>
                <div className={classNames(getComparison(evaluation[modelKey].score, evaluation[modelKey === 'model1' ? 'model2' : 'model1'].score))}>{evaluation[modelKey].score !== undefined ? evaluation[modelKey].score : '-'}</div>
            </div>
        )}
    </div>
);

const EvaluationComparisonResult = ({ evaluation1, evaluation2, testset }) => {
    const [mergedEvaluations, setMergedEvaluations] = useState([])
    const TESTSET_MAP = [
        <div className="grid grid-cols-2 items-center text-sm mt-3 text-left">
            <div>{evaluation1 && <EvaluationComparisonInternalResultColumn evaluations={mergedEvaluations} modelKey="model1" />}</div>
            <div>{evaluation2 && <EvaluationComparisonInternalResultColumn evaluations={mergedEvaluations} modelKey="model2" />}</div>
        </div>,
        <div className="grid grid-cols-2 items-center text-sm mt-3 text-left">
            <div>{evaluation1 && <EvaluationComparisonEsc50ResultColumn evaluations={mergedEvaluations} modelKey="model1" />}</div>
            <div>{evaluation2 && <EvaluationComparisonEsc50ResultColumn evaluations={mergedEvaluations} modelKey="model2" />}</div>
        </div>,
        <div className="grid grid-cols-2 items-center text-sm mt-3 text-left">
            <div>{evaluation1 && <EvaluationComparisonDcase2018Task2ResultColumn evaluations={mergedEvaluations} modelKey="model1" />}</div>
            <div>{evaluation2 && <EvaluationComparisonDcase2018Task2ResultColumn evaluations={mergedEvaluations} modelKey="model2" />}</div>
        </div>,
    ]

    useEffect(() => {
        const eval1 = evaluation1?.threshold_evaluation_values || [];
        const eval2 = evaluation2?.threshold_evaluation_values || [];
        setMergedEvaluations(getMergedEvaluation(eval1, eval2));
    }, [evaluation1, evaluation2]);

    return mergedEvaluations.length !== 0 && (
        <>{TESTSET_MAP.at(testset)}</>
    );
};

export default EvaluationComparisonResult